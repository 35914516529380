var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      attrs: {
        name: _vm.name,
        width: "420px",
        "class-own": "dialog-apply-samples",
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c(
                "div",
                { staticStyle: { "font-size": "16px", "font-weight": "400" } },
                [_vm._v(" Добавление шаблона суточных квот ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "m-b-25" },
        [
          _c("div", { staticClass: "hardWarning" }, [
            _c(
              "div",
              { staticClass: "hardWarning__button" },
              [_c("Icon", { attrs: { name: "IconAttentionCircle" } })],
              1
            ),
            _c("div", { staticClass: "hardWarning__text" }, [
              _vm._v(" " + _vm._s(_vm.warningText) + " "),
            ]),
          ]),
          _vm.hasSamples
            ? [
                _c("div", { staticClass: "samples__title" }, [
                  _vm._v(" Выберите день, на который применится шаблон "),
                ]),
                _c(
                  "el-form",
                  [
                    _c("IqSelectDateForm", {
                      attrs: {
                        options: _vm.options,
                        type: "date",
                        format: "dd.MM.yyyy",
                        placeholder: "Укажите дату",
                        size: "large",
                        width: "100%",
                        clearable: false,
                      },
                      model: {
                        value: _vm.applyDate,
                        callback: function ($$v) {
                          _vm.applyDate = $$v
                        },
                        expression: "applyDate",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "sample__buttons" },
        [
          _c("iq-mobile-button", {
            attrs: {
              "button-style": "padding: 9px; width: 100%; cursor: pointer",
              "text-style": "color: #000000; font-weight: 500",
              title: _vm.titleCancel,
            },
            on: { onClick: _vm.closeDialog },
          }),
          _c("iq-mobile-button", {
            attrs: {
              "button-style":
                "padding: 9px; width: 100%; background: #DB6D39; border-color: #DB6D39; cursor: pointer",
              "text-style": "color: white; font-weight: 500",
              title: _vm.titleOk,
            },
            on: { onClick: _vm.handleApplySamples },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }