<template>
  <Dialog
    v-loading="isLoading"
    :name="name"
    width="420px"
    class-own="dialog-apply-samples"
  >
    <template #title>
      <div style="font-size: 16px; font-weight: 400">
        Добавление шаблона суточных квот
      </div>
    </template>

    <div class="m-b-25">
      <div class="hardWarning">
        <div class="hardWarning__button">
          <Icon name="IconAttentionCircle" />
        </div>

        <div class="hardWarning__text">
          {{ warningText }}
        </div>
      </div>

      <template v-if="hasSamples">
        <div class="samples__title">
          Выберите день, на который применится шаблон
        </div>
        <el-form>
          <IqSelectDateForm
            v-model="applyDate"
            :options="options"
            type="date"
            format="dd.MM.yyyy"
            placeholder="Укажите дату"
            size="large"
            width="100%"
            :clearable="false"
          />
        </el-form>
      </template>
    </div>

    <div class="sample__buttons">
      <iq-mobile-button
        button-style="padding: 9px; width: 100%; cursor: pointer"
        text-style="color: #000000; font-weight: 500"
        :title="titleCancel"
        @onClick="closeDialog"
      />
      <iq-mobile-button
        button-style="padding: 9px; width: 100%; background: #DB6D39; border-color: #DB6D39; cursor: pointer"
        text-style="color: white; font-weight: 500"
        :title="titleOk"
        @onClick="handleApplySamples"
      />
    </div>
  </Dialog>
</template>

<script>
import { FETCH_LIMIT_QUOTAS } from '@/views/home/store/actions'
import { GET_TERMINAL_CURRENT_ID } from '@/store/actions'
import { QUOTA_APPLY_SAMPLES } from '@/constants/dialogs'
import { getLocalBriefDate, pickerOptions } from '@/core'
import { mapActions, mapGetters } from 'vuex'
import { showMessageSuccess } from '@/UI/message'
import Api from '@/api'
import Dialog from '@/UI/dialog/Dialog'
import Icon from '@/UI/icon/Icon.vue'
import IqMobileButton from '@/views/ui/components/mobileButton/IqMobileButton'
import IqSelectDateForm from '@/views/ui/components/select/IqSelectDateForm.vue'

export default {
  name: 'DialogApplySamples',
  components: { IqSelectDateForm, Icon, IqMobileButton, Dialog },
  data() {
    return {
      name: QUOTA_APPLY_SAMPLES,
      applyDate: new Date(),
      options: pickerOptions,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({ currentTerminalId: GET_TERMINAL_CURRENT_ID }),
    hasSamples() {
      return Boolean(this.getDialog(this.name)?.data)
    },
    warningText() {
      return this.hasSamples
        ? 'ОБЯЗАТЕЛЬНО проверьте актуальность ВСЕХ КВОТ по суточному шаблону\n' +
            '          перед нажатием "Добавить". После применения шаблона квоты сразу же\n' +
            '          вступают в силу!'
        : 'У вас нет шаблона суточных квот. Для применения шаблона его необходимо вначале настроить!'
    },
    titleCancel() {
      return this.hasSamples ? 'Отменить' : 'Понятно'
    },
    titleOk() {
      return this.hasSamples ? 'Добавить' : 'Настроить шаблон'
    },
  },
  methods: {
    ...mapActions({
      refreshPage: FETCH_LIMIT_QUOTAS,
    }),
    async handleApplySamples() {
      if (this.hasSamples) {
        try {
          this.isLoading = true
          const params = {
            unload_id: this.currentTerminalId,
            date: getLocalBriefDate(this.applyDate),
          }

          await Api.quotaSamples.applySamples(params)
          await this.refreshPage()
          showMessageSuccess('Шаблоны сутоных квот применены')
        } catch (e) {
          console.log(e.response.data)
        } finally {
          this.isLoading = false
          this.closeDialog()
        }
      } else {
        await this.$router.push({ name: 'Samples' })
        this.closeDialog()
      }
    },
    closeDialog() {
      this.setDialog({ name: this.name }, false)
    },
  },
}
</script>

<style lang="sass" scoped>
.dialog-apply-samples
  .hardWarning
    display: flex
    padding: 12px
    margin-bottom: 15px
    background: #FEF0F0
    border: 1px solid #FDE2E2
    border-radius: 4px
    &__button
      margin-right: 10px
      svg
        width: 20px !important
        height: 20px !important
    &__text
      padding-top: 2px
      font-size: 14px
      line-height: 22px
  .samples__title
    margin-bottom: 5px
    font-size: 14px
    line-height: 22px
  .sample__buttons
    display: flex
    gap: 16px
</style>
